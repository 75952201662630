import React from 'react'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Quote } from '../../components/Quote'
import { CallToAction, GetStarted } from '../../components/CallToAction'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';
import { CenteredImg, WidthImg } from '../../common/Style';

import SfOnLaptop from '../images/sfOnLaptop.png'
import SfNotes from '../images/sfNotes.svg'
import TeamDashHalf from '../images/teamDashHalf.svg'

import OldWay from '../images/oldWay.svg';
import ActionEvent from '../images/actionEvent.svg';
import SalesforceDashboard from '../images/salesforceDashboard.png'
import IntegrationsGrid from '../images/integrationsGrid.svg'
import BestSalesforce from '../images/bestSalesforce.svg';
import ItMonitoring from '../images/itMonitoring.svg';




const SolutionsPage = () => (
  <Default>
    <Helmet>
      <title>Truly | Pain-free Sales Calls</title>
    </Helmet>

    <WrapperBackground
      color={colors.trulyDark}
      background='headsetPerson2'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width="1">
          <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            For Quantitative Revenue Operations Leaders
          </PageTitle2>
          <PageTitle as="p" color={colors.white} mb="1.5rem" center>
            Automate Your Entire Sales Funnel In Salesforce
          </PageTitle>
        </Box>
      </Section>
    </WrapperBackground>

    <Quote></Quote>



    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={ActionEvent} alt="Automated pipeline inspection"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          24/7 COVERAGE OF YOUR ENTIRE REVENUE FUNNEL
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            With Truly, you can finally get peace of mind that your sales machine is working as it should.  
          <br/><br/>
            Our system will automatically analyze your activity and pipeline for everything that you care about - sales skills, process adherence, account coverage, customers at risk and more.
          <br/><br/>
            And when issues are found, it can take automatic action on your behalf, whether it's creating a follow up task for your rep with coaching guidance, notifying the manager of the issue or triggering an automation. 
        </Large>
        </Box>
      </Section>
    </WrapperSolid>


    <WrapperSolid color='#292929'>
      <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
          NO MORE SPREADSHEETS. NO MORE ETL MADNESS.
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem">
           Truly is the only sales reporting solution designed to make Salesforce your one true data/reporting hub.
          <br/><br/>
           Our SFDC native data pipeline  computes all of your activity and funnel metrics, and aggregates the data in tables that make sales reporting easy and fast in Salesforce.
          <br/><br/>
            Customizable metrics allow you to quantify everything from <u>sales behaviors</u> (talking too much, not using right language) to <u>deal execution</u> (not following up on opportunities, sandbagging, etc) to <u>pipeline management</u> (pipeline coverage, stage conversion rates, pipeline velocity, etc)
          </Large>
        </Box>
        <Box width={[1/2, 1/2]}>
          <CenteredImg src={SalesforceDashboard} alt="No more spreadsheets"/>
        </Box>
      </Section>
    </WrapperSolid>


    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={BestSalesforce} alt="Sales complete data ownership"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          GET COMPLETE OWNERSHIP OF YOU DATA
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            Truly is unique in that it pushes all of your raw and derived data into SFDC, giving you complete ownership of your data.
          <br/><br/>
            This means that if you ever decide to stop using our products, you retain all of the data without having to go through a complex backup process.  It also means that you can customize the entire reporting experience in SFDC, or export all of the data to a third party system for further analysis using any 'out of the box' salesforce connector (no custom development required).
          </Large>
        </Box>
      </Section>
    </WrapperSolid>



    <WrapperSolid color='#292929'>
      <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
          ACHIEVE SALES STACK FREEDOM
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem" center>
           Truly's products are all modular, using Salesforce as a central data/API layer.  This means that you can either deploy Truly as a complete solution, or mix and match functionality with other sales tools, to meet your unique challenges.
          <br/><br/>
            Our approach to consolidating reporting in Salesforce also eliminates lock-in with legacy sales tool vendors, allowing you to easily swap out tools 'at will' across your organization without having to worry about impacts to your reporting data pipeline.
          </Large>
        </Box>
        <Box width={[1/2, 1/2]}>
          <CenteredImg src={ItMonitoring} alt="Sales stack"/>
        </Box>
      </Section>
    </WrapperSolid>




    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={IntegrationsGrid} alt="Coaching"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          EASY TO ADOPT & DEPLOY
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            Rated the "Easiest To Implement" solution on G2Crowd by Enterprise and Midmarket customers, our Salesforce native architecture makes us the easiest solution to deploy.  
          <br/><br/>
            Our modular approach also makes it possible to deploy Truly as an all-in-one or a complementary solution to your existing stack.
          <br/><br/>
            Our dedicated implementation management team can be called on as often as you like, whether it's to onboard more Truly products, integrate Truly with third party applications or give your reps a refresher course in how to make the most of our platform.  You set the pace -- and we follow.
          </Large>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperBackground background="headsetPerson1" gradientType="purple">
      <CallToAction/>
    </WrapperBackground>

    <WrapperBackground background="sf" gradientType="black">
      <GetStarted/>
    </WrapperBackground>
  </Default>

)

export default SolutionsPage

